<template>
  <div class="send-email-main">
    <div class="send-email-panel">
      <v-card flat class="edit-panel">
        <div class="px-4" :class="{ 'mt-3': errorHas($options.name) || successHas($options.name) }">
          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)"
            :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
            :message="successGet($options.name)" status="success"></status>
          <!-- <div class="font-regular hb-text-light">Enter Your Email Text.</div> -->
        </div>

        <div v-if="!showConfirm">
          <div class="breadcrumb-communication">
            <hb-breadcrumb @click="goBack()">
              <span class="back-text-communication">
                Send Communication
              </span>
            </hb-breadcrumb>
          </div>
          <v-divider></v-divider>
          <div class="mt-4 ml-4 hb-text-light">
            <p>Draft your email or choose a communication template to start with.</p>
          </div>
          <div class="mt-4">
            <v-divider></v-divider>
            <hb-form label="Template" :divider=true>
              <template v-slot:tooltipBody>
                Search for an existing template from the template manager library
              </template>
              <HbAutocomplete v-model="temmplateName" :items="templateNames" v-validate="'required|max:45'"
                item-text='name' item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box"
                data-vv-as="Autocomplete Box" :error="errors.has('default.autocomplete-box')"
                placeholder="Select Template"  single-line @change="appendtemplate($event)"
                @click:clear="clearTemplate" />
            </hb-form>
            <hb-form label="Subject">
              <hb-text-field v-model.lazy="form.subject"  placeholder="Subject Title" required>
              </hb-text-field>
            </hb-form>
          </div>

          <rich-text-editor v-model="form.email" proseHeight="200px" class="rich-text-custom" @input=" form.email.includes('[') && triggerAddMergeFieldByInput(form.email)" />


        </div>

        <div v-show="showConfirm">
          <div class="breadcrumb-communication pa-4">
            <hb-breadcrumb @click="showConfirm = false, disable = false">
              <span class="back-text-communication">
                Confirm & Send to Recipients
              </span>
            </hb-breadcrumb>
          </div>
          <v-divider></v-divider>
          <div class="pa-4 hb-text-light">
            <span>This message will be sent to ALL the recipients you selected below:</span>
          </div>
          <v-divider></v-divider>
          <div class="pa-5">
            <hb-data-table :loading="!contacts.length > 0" :height="computedTableHeight" fixed-header  disable-sort :headers="headers" :items="contacts"
              style="overflow-y: auto;max-width: 600px; width: 100%;">
              <template v-slot:item.email="{ item }">
                <span v-if="item.email">{{ item.email }}</span>
                <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                  Email address missing!
                </v-alert>
              </template>
              <template v-slot:item.first="{ item }">
                {{ item.first }} {{ item.last }}
              </template>
              <template v-slot:footer>
                <div class="pa-2 custom-footer">
                  Total Recipients: {{ contacts.length }}
                </div>
              </template>
            </hb-data-table>
          </div>
        </div>
      </v-card>
      <div class="footer-action-bar">
        <hb-bottom-action-bar v-if="!showConfirm" cancel-off class="bottom-action-bulk">
          <template v-slot:left-actions>
            <div class="left-actions-container">
              <hb-menu>
                <template v-slot:menu-activator>
                  <hb-btn color="secondary" @click="handleAddMergeClick">
                     Add Merge Fields
                  </hb-btn>
                  <hb-btn icon @click="toggleList = true" mdi-code="mdi-paperclip" tooltip="Attach File"></hb-btn>
                </template>
                <v-list v-if="toggleList">
                  <v-list-item class="pointer-cursor" @click="showFile = true">
                    Upload
                  </v-list-item>
                  <v-list-item class="pointer-cursor" @click="openUploadDocs(0)">
                    Generate Documents (Non-Signature)
                  </v-list-item>
                  <v-list-item class="pointer-cursor" @click="openUploadDocs(1)">
                    Generate Documents (Signature)
                  </v-list-item>
                </v-list>
              </hb-menu>
              <hb-chip v-if="attachments.length" @click="attachmentRemoval(true)" @chip-click="noteChipClick(item)"
                editable>
                {{ attachments[0].name }}
              </hb-chip>
              <hb-chip v-if="selectedDocumentId" @click="attachmentRemoval(false)" @chip-click="noteChipClick(item)"
                editable>
                {{ selectedDocument.name }}
              </hb-chip>
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn @click="goBack()" color="secondary">Back</hb-btn>
            <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
          </template>
        </hb-bottom-action-bar>

        <hb-bottom-action-bar v-if="showConfirm" cancel-off>
          <template v-slot:left-actions>

          </template>
          <template v-slot:right-actions>
            <hb-btn color="secondary" @click="showConfirm = false, disable = false">Back</hb-btn>
            <hb-btn color="primary" :loading="loading" :disabled="disable" @click="save">Confirm & Send</hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>
    </div>



    <add-file :show="showFile" :onClose='onFileClose' :getAttachments="getAttachments" :allowMultiple="false" :clearData="clearfiles">
    </add-file>

    <hb-modal size="large" title="Browse Files" v-model="openUploadDocsStatus" @close="openUploadDocsStatus = false">
      <template v-slot:content>
        <v-app style="max-height: 699px; overflow-y: hidden;">
          <v-navigation-drawer v-model="openUploadDocsStatus" class="inside-card-drawer" absolute disable-resize-watcher
            width="200px" color=#F9FAFB>
            <v-list>
              <v-list-item v-for="(option, index) in options" :key="index" @click="selectedOption = index"
                :class="{ 'selected': selectedOption === index }">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 15px; display: flex; flex-direction: column;"><span>{{
                    option.label }}</span><span v-if="option.subLabel">{{ option.subLabel
                      }}</span></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-main style="padding: 0 0 0 200px;">
            <div v-if="selectedOption == 0">
              <upload-bulk-unsigned-doc @selected-document-id="addDocumentId"></upload-bulk-unsigned-doc>
            </div>
            <div v-if="selectedOption == 1">
              <upload-bulk-signed-doc @selected-document-id="addDocumentId"></upload-bulk-signed-doc>
            </div>
          </v-main>
        </v-app>
      </template>
      <template v-slot:right-actions>
        <hb-btn :disabled="!selectedDocument?.id" @click="attachdoc()">Attach</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import api from '../../../assets/api.js';
import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import addFile from '../../communication_center/AddFile.vue'
import RichTextEditor from '../../assets/RichTextEditor.vue'
import { notificationMixin } from '@/mixins/notificationMixin.js';
import MergeFields from "../../includes/MergeFields";
import UploadBulkUnsignedDoc from './UploadBulkUnsignedDoc.vue';
import UploadBulkSignedDoc from './UploadBulkSignedDoc.vue';

export default {
  name: "BulkSendEmail",
  data() {
    return {
      headers: [
        { text: 'Person', align: 'start', value: 'first' },
        { text: 'Email', align: 'start', value: 'email' },
      ],
      disable: false,
      loading: false,
      temmplateName: '',
      showConfirm: false,
      form: {
        subject: '',
        email: '',
      },
      contacts: [],
      showFile: false,
      attachments: [],
      showMergeFields: false,
      templateNames: [],
      filteredList: [],
      toggleList: false,
      openUploadDocsStatus: false,
      selectedOption: 0,
      options: [
        { label: 'Generate Documents', subLabel: ' (Non-Signature)' },
        { label: 'Generate Documents', subLabel: ' (Signature)' },

      ],
      selectedDocument: null,
      selectedDocumentId: null,
      isTriggeredByButton: false,
      contact_ids: [],
      clearfiles: false
    }
  },
  mixins: [notificationMixin],
  props: ['items'],
  created() {
    this.getContactDetails();
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    UploadBulkSignedDoc,
    UploadBulkUnsignedDoc,
    'add-file': addFile,
    'rich-text-editor': RichTextEditor,
    MergeFields
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      triggerMergeFields: 'addMergeFieldsStore/triggerMergeFields',
      selectedMergeField: 'addMergeFieldsStore/selectedMergeField',
      actionSource: 'addMergeFieldsStore/actionSource',
      addMergeButtonClickFlag: 'addMergeFieldsStore/addMergeButtonClickFlag',
      contactsList: "reportStore/getBulkContactsList"
    }),
    computedTableHeight() {
      const maxHeight = 400; // Define a maximum height for the table
      const rowHeight = 48;  // Approximate height of each row in pixels
      const headerHeight = 48; // Approximate height of the table header

      // Calculate height based on the number of rows plus the header height
      const calculatedHeight = (this.contacts.length * rowHeight) + headerHeight;

      // Ensure height does not exceed the maximum
      return `${Math.min(calculatedHeight, maxHeight)}px`;
    }
  },
  mounted() {
    this.getBaseTemplateData()
  },
  methods: {
    triggerAddMergeFieldByInput(txt) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        const bracketMatch = /\[[^\]]+[^\]]*$/mg;
        const isMergeFlag = txt.replace(/<\/?[^>]+(>|$)/g, "").match(bracketMatch);
        const openBracketMatch = txt.match(/\[[^\]]+$/);
        if (isMergeFlag) {
          if (openBracketMatch[0]) {
            this.setFilterInput({
              filterInput: openBracketMatch[0].replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9\s]/g, "")
            })
            this.setTriggerMergeFields({
              actionSource: 'bulk-edit-mail',
              addMergeButtonClickFlag: false
            })
          }
        }
      }, 500)
    },

    insertMergeField(txt) {
      if (this.actionSource === 'bulk-edit-mail') {
        if (this.addMergeButtonClickFlag) {
          this.form.email = this.form.email === undefined ? txt : this.form.email + txt;
          if (this.$vuetify.breakpoint.xs) {
            this.reset()
          }
          this.setFilterInput({
            filterInput: ""
          })
        }
        else {
          const updatedMessage = this.form.email.replace(/\[[^\]]+$/, txt);
          this.form.email = updatedMessage;
          if (this.$vuetify.breakpoint.xs) {
            this.reset()
          }
          this.setFilterInput({
            filterInput: ""
          })
        }
      }
    },


    ...mapActions({
      getBulkContacts: 'reportStore/addBulkContacts',
      setTriggerMergeFields: 'addMergeFieldsStore/setTriggerMergeFields',
      setActionSource: 'addMergeFieldsStore/setActionSource',
      setFilterInput: "addMergeFieldsStore/setFilterInput",
      reset: "addMergeFieldsStore/reset",
    }),
    clearTemplate() {
      this.form.email = ''
      this.form.subject = ''
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId([this.facilityList[0].id]); // only one property will select at a time
      const templateFilterd = templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.filter(template => template.type === 'email').map(template => ({ name: template.name, template_id: template.template_id }));
    },
    addDocumentId(data) {
      this.selectedDocument = data;
    },
    async getAllTemplatesWithId(properties) {
      return await this.getTemplatesById(properties);
    },
    async getTemplatesById(propertyIds) {
      try {
        const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
        const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
        return response.templates;
      } catch (error) {
        console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
        throw error; // Rethrow the error to propagate it up
      }
    },
    async appendtemplate(id) {
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (res.template.body && res.template.subject) {
            this.form.email = res.template.body
            this.form.subject = res.template.subject
          }
          else {
            this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
          }
        })
      } catch (error) {
        throw error;
      }
    },
    async goBack() {
      await this.$emit('back');
      EventBus.$emit('send_email:back', true);
      EventBus.$emit('disablecheckbox:report', false);
    },
    onFileClose() {
      this.showFile = false;
      this.toggleList = false;
    },
    getAttachments(attachments) {
      this.attachments = attachments;
      console.log(this.attachments);
      this.onFileClose();
      this.clearfiles = true
    },
    closeWindow() {
      this.resetAction();
    },
    async getContactDetails() {
      let items = this.items.map(item => {
        return {
          id: item.tenant_id || item.contact_id || item.lead_id,
          lease_id: item.lease_id,
          national_account_id: item?.na_id
        }
      });

      let contacts_data = this.contactsList.filter(data => data.id);

      let response = await api.post(this, api.CONTACTS + 'bulk-config', { contact_ids: contacts_data.length ? contacts_data : items.filter(i => i.id) });

      this.contacts = response.contacts || []

      this.filteredList = this.contactsList.length ? this.contactsList : items || [];
    },
    setEditForm() {

      if (!this.form.subject) {
        this.errorSet(this.$options.name, "Please enter a subject");
        return;
      };

      if (!this.form.email) {
        this.errorSet(this.$options.name, "Please enter your message");
        return;
      };

      this.showConfirm = true;
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      console.log("ATTACHMENTS", this.attachments)
      let response = await api.postFile(this, api.CONTACTS + '/uploads', body, this.attachments);

      return response

    },
    async save() {
      this.loading = true;
      try {
        let form = {
          subject: this.form.subject,
          email: this.form.email,
          contacts: this.filteredList,
          attachments: this.attachments,
          document: this.selectedDocumentId,
          type: this.selectedDocumentId ? this.selectedDocument.type : "",
          upload_id: null,
          delivery_method: 'standard_email'
        };
        // Handle file upload if there are attachments
        if (this.attachments.length) {
          let fileUploadResponse = await this.upload(form.contacts);
          form.upload_id = fileUploadResponse.uploads.id;
          form.attachments[0].content_type = fileUploadResponse.uploads.mimetype;
        }
        // Send the email via the API
        let response = await api.post(this, api.CONTACTS + 'bulk-send-email', form);
        if (!response) {
          this.loading = false;
          this.disable = false;
          return;
        }
        // Success handling
        this.showMessageNotification({ type: 'success', description: 'Messages Sent' });
        EventBus.$emit('unit_edited');
        this.$emit('close-bulk-edit');
        this.disable = true;
      } catch (error) {
        // Error handling
        this.errorSet(this.$options.name, "Failed to send messages. Please try again.");
        console.error("Error sending email:", error);
      } finally {
        // Ensure loading is set to false, regardless of success or failure
        this.loading = false;
      }
      // Reset contacts after the process
      this.getBulkContacts([]);
    },
    openUploadDocs(x) {
      this.openUploadDocsStatus = true
      this.selectedOption = x
    },
    attachdoc() {
      this.selectedDocumentId = this.selectedDocument?.id
      this.openUploadDocsStatus = false;
    },
    attachmentRemoval(attachment) {
      if (attachment === true) {
        this.attachments = []
      } else {
        this.selectedDocument = {}
        this.selectedDocumentId = null;
      }
    },
    handleAddMergeClick(){
      this.setTriggerMergeFields({
        actionSource: 'bulk-edit-mail',
        addMergeButtonClickFlag: true
      }) 
    },
  },
  watch: {
    selectedMergeField(newValue){
      if(newValue){
        this.insertMergeField(newValue)
      }
    }
  }
}

</script>
<style scoped>
.strike {
  text-decoration: line-through;
  color: #a0a0a0;
}

.enhanced {
  font-weight: 500;
}

.primary-color {
  color: #00727A;

}

.panel-footer {
  border-top: none !important;
  z-index: 5;
  width: 100%;
  bottom: 0;
  position: static !important;
}

.selected {
  /* background-color: rgba(0, 0, 255, 0.1); Highlight selected option */
  background: #e0f5f5;
  border-left: 4px solid #00848E;
  width: 100%
}

.custom-list {
  max-width: 200px;
  /* Adjust the maximum width as needed */
}

.inside-card-drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  visibility: visible !important;
  transform: none !important;
}

.v-application--wrap {
  min-height: 100%;
}

.v-navigation-drawer--close {
  visibility: visible !important;
}

.v-card-class-main {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #e3e8ed;
  box-shadow: none !important;
}

.no-border-table {
  border-collapse: collapse;
  /* Ensures no separation between table cells */
}

.no-border-table th,
.no-border-table td {
  border: none !important;
  /* Removes borders from table headers and cells */
  padding: 5px !important;
  /* Adjusts padding for a cleaner look */
}

.back-text-confirmation {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #101318;
  display: block;
}

.breadcrumb-communication {
  padding: 16px;
}

.back-text-communication {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #101318;
  display: block;
}

.breadcrumb-communication ::v-deep a.hb-link i.mdi-chevron-left {
  color: #101318 !important;
}


</style>

<style lang="scss" scoped>
.send-email-main {
  height: 100%;

  .send-email-panel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .edit-panel {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .footer-action-bar {
      height: 60px;
      position: sticky;
      bottom: 0;
      width: 100%;
    }
  }

  .left-actions-container {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Optional: adds spacing between elements */
    flex-wrap: nowrap;
    /* Prevents elements from wrapping to the next line */
  }
}

.rich-text-custom {
  ::v-deep .editor-container {
    padding-bottom: 0px !important;
  }

  ::v-deep .ProseMirror {
    overflow-y: hidden !important;
  }
}

.custom-footer {
  border: 1px solid rgb(223, 227, 232);
  font-weight: 500;
}

</style>
