<template>
    <div>
        <v-flex class="hb-flex">
            <div class="section-content pa-3">
                <div class="primary-section-content">
                    <v-row class="mx-4 mb-2 font-regular mt-4 hb-text-night-light">
                        Select any number of space groups that you want to update.
                    </v-row>
                    <v-row no-gutters>
                        <v-col v-for="(route, index) in actions" cols="12" class="px-4 pt-2">
                            <hb-btn :key="index" accordion @click="sectionClicked(route)">{{ route.title }} </hb-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-flex>
        <hb-modal v-model="dialog" size="medium" title="Required Permission" confirmation footer-off="true">
            <template v-slot:content>
                <div class="px-6 py-4">
                    {{ permissionMessage }}
                </div>
            </template>
        </hb-modal>
        <AssignApwPlan v-model="showAssignApwPlan" v-if="!selectSpace && showAssignApwPlan" :type="'bulk'" @assign-plan-bulk="savePlanBulk"></AssignApwPlan>
        <hb-modal
            v-else
            v-model="selectSpace"
            size="medium"
            title="Select space groups in order to update."
            confirmation
            @close="selectSpace = false"
            :footer-off="true"
        >
            <template v-slot:content>
                <div class="pa-4">
                Select any number of space groups that you want to update.
                </div>
            </template>
        </hb-modal>
        <confirmation-pop-up v-model="planStatusConfirm" :type="planStatusAction.toLowerCase()" :message="`Are you sure you want to ${planStatusAction} the Advanced Reservations and Rentals plan you assigned to this space groups?`" :title="`Confirm ${planStatusAction}`" @update-status="planStatusUpdate"></confirmation-pop-up>
    </div>
</template>

<script>
import api from "../../../assets/api.js";
import { mapState, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from "../../../EventBus.js";
import AssignApwPlan from "../../advanced_purchase_window/AssignApwPlan.vue";
import ConfirmationPopUp from "../../advanced_purchase_window/ConfirmationPopUp.vue";
export default {
    name: "BulkAssignApw",
    data() {
        return {
            showAssignApwPlan: false,
            actions: [
                { key: "assignInstore", title: "Assign Instore Plan", permission: 'assign_plans', permissionMessage: 'assign instore plan'},
                { key: "assignOnline", title: "Assign Online Plan", permission: 'assign_plans', permissionMessage: 'assign online plan'},
                { key: "enablePlan", title: "Enable Advanced Reservations and Rentals Management", permission: 'activate_or_deactivate_plans', permissionMessage: 'enable advanced reservations and rentals management'},
                { key: "disablePlan", title: "Disable Advanced Reservations and Rentals Management", permission: 'activate_or_deactivate_plans', permissionMessage: 'disable advanced reservations and rentals management'},
            ],
            title: `Confirmation`,
            message: `Are you sure you want to <span class="font-weight-medium">Approve All Rent Changes</span> for the selected Tenants?`,
            approvalLoading: false,
            apwItems: [],
            unit_group_ids: [],
            selectSpace: false,
            planStatusConfirm: false,
            planStatusAction: '',
            planChannelType: '',
            dialog: false,
        };
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [notificationMixin],
    components: {
        AssignApwPlan,
        ConfirmationPopUp
    },
    computed: {
        ...mapGetters({
            selectedProperty: "propertiesStore/defaultProperty",
            hasPermission: 'authenticationStore/rolePermission',
        }),
    },
    methods: {
        sectionClicked(action){
            this.unit_group_ids = this.items?.map((item) => item.spacegroup_id) || [];
            if(this.unit_group_ids.length == 0){
                this.selectSpace = true;
            }else{
                if(action.key === 'assignInstore'){
                    if (this.hasPermission(action.permission)){
                        this.showAssignApwPlan = true;
                        this.planChannelType = 'instore'
                    } else {
                        this.showPermissionModal(action.permissionMessage);
                    }
                }else if (action.key === 'assignOnline'){
                    if (this.hasPermission(action.permission)) {
                        this.showAssignApwPlan = true;
                        this.planChannelType = 'online'
                    } else {
                        this.showPermissionModal(action.permissionMessage);
                    }
                }else if (action.key === 'enablePlan'){
                    if (this.hasPermission(action.permission)) {
                        this.planStatusConfirm = true;
                        this.planStatusAction = 'Enable'
                    } else {
                        this.showPermissionModal(action.permissionMessage);
                    }
                }else if (action.key === 'disablePlan'){
                    if (this.hasPermission(action.permission)) {
                        this.planStatusConfirm = true;
                        this.planStatusAction = 'Disable'
                    } else {
                        this.showPermissionModal(action.permissionMessage);
                    }
                }
            }
        },
        showPermissionModal(permissionAction) {
            this.dialog = true;
            this.permissionMessage = `You do not have permission to ${permissionAction}.`;
        },
        async planStatusUpdate(){
            try {
                let data = {
                    spacegroup_ids: this.unit_group_ids,
                    plan_status: this.planStatusAction == "Enable" ? 1 : 0,
                };
                await api.post(this, api.APW_MANAGEMENT +'properties/' + this.selectedProperty?.id + '/bulk-plan-status', data);
                this.showMessageNotification({ type: 'success', description: this.planStatusAction == "Enable"? "Enabled Successfully" : "Disabled Successfully" });
                EventBus.$emit('spaceGroupReportUpdated')
                this.planStatusConfirm = false;
            } catch (error) {
                console.log(error);
            }
        },
        async savePlanBulk(planId) {
            try {
                let data = {
                    spacegroup_ids: this.unit_group_ids,
                }
                if(this.planChannelType == 'instore'){
                    data['instore_apw_plan_id'] = planId;
                }else if(this.planChannelType == 'online'){
                    data['online_apw_plan_id'] = planId;
                }
                await api.post(this, api.APW_MANAGEMENT +'properties/' + this.selectedProperty?.id + '/bulk-assign-plan', data);
                this.showMessageNotification({ type: 'success', description: "Plan Assigned Successfully"});
                EventBus.$emit('spaceGroupReportUpdated')
                this.showAssignApwPlan = false;
            }catch (error) {
                console.log(error);
            }
        }
    },
};
</script>